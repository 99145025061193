<template>
  <b-card>
    <div class="text-right mb-1">
      <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left /> Regresar</b-button>
    </div>

    <b-overlay :show="isLoadingMonetizingConfig || isSavingMonetizingConfig" rounded class="">
      <validation-observer v-slot="{ invalid }" ref="MonetizingConfigCreation">
        <b-form @submit.prevent="saveConfiguration">
          <b-row  class="justify-content-center">
            <b-col>
              <b-row>
                <b-col sm="">
                  <validation-provider name="Hotel" rules="required">
                    <b-form-group slot-scope="{ valid, errors }" label="Hotel">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="newConfig.hotel"
                        :clearable="false"
                      >
                        <option :value="null" disabled>Seleccione una opción</option>
                        <option v-for="h in hotels" :key="h.id" :value="h.id">{{ h.name }}</option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm="">
                  <validation-provider name="Beneficio" rules="required">
                    <b-form-group slot-scope="{ valid, errors }" label="Beneficio">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="newConfig.benefit"
                        :clearable="false"
                      >
                        <option :value="null" disabled>Seleccione una opción</option>
                        <option
                          v-for="benefitOption in benefitOptions"
                          :key="benefitOption.id"
                          :value="benefitOption.id"
                        > {{ benefitOption.name }}
                        </option>                  </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="" sm="">
                  <ValidationProvider name="Categoría de Habitación">
                    <b-form-group label="Categoría de Habitación" slot-scope="{ valid, errors }">
                      <v-select
                        v-model="newConfig.roomsCategory"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        label="name"
                        @option:deselected="deleteRoomType"
                        :options="roomsConfig"
                        :state="errors[0] ? false : valid ? true : null"
                      />
                      <div class="text-danger" style="font-size: 0.857rem">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="" sm="">
                  <ValidationProvider name="Desde" rules="required|min_value:1|max_value:99">
                    <b-form-group label="Desde" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="number"
                        v-model="newConfig.rooms_from"
                        @keypress="onlyNumberWithDecimal"
                        :state="errors[0] ? false : valid ? true : false"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="" sm="">
                  <ValidationProvider name="Hasta" rules="required|min_value:1|max_value:99">
                    <b-form-group label="Hasta" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="number"
                        v-model="newConfig.rooms_to"
                        @keypress="onlyNumberWithDecimal"
                        :state="errors[0] ? false : valid ? true : false"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4" sm="">
                  <ValidationProvider name="Monto" rules="required|max:11">
                    <b-form-group label="Monto" slot-scope="{ valid, errors }">
                      <b-form-input
                        class="form-control"
                        type="number"
                        step="0.01"
                        @keypress="onlyNumberWithDecimal"
                        v-model="newConfig.amount"
                        :state="errors[0] ? false : valid ? true : false"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4">
                  <validation-provider name="Divisa" rules="required">
                    <b-form-group slot-scope="{ valid, errors }" label="Divisa">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="newConfig.currency"
                        :clearable="false"
                      >
                        <option :value="null" disabled>Seleccione una opción</option>
                        <option v-for="cur in currencies" :key="cur.id" :value="cur.currencyid">
                          {{ cur.code }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col align-self="end" md="1" class="mt-1">
              <ValidationProvider rules="" name="status">
                <b-form-group>
                  <label>Status</label>
                  <b-form-checkbox v-model="newConfig.status" switch />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <hr>

          <b-button
            variant="primary"
            type="submit"
            class="float-right"
            :disabled="invalid || isLoadingMonetizingConfig || isSavingMonetizingConfig"
          > Guardar
          </b-button>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import { validYears } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations } from "vuex";
import { utils } from "@/modules/fivesClub/mixins/utils"
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select';

export default {
  mixins: [utils],
  directives: { Ripple, },
  components: {
    vSelect
  },
  async mounted() {
    await this.init()
  },
  data() {
    return {
      isLoadingMonetizingConfig:false,
      isSavingMonetizingConfig:false,

      newConfig: {
        roomsCategory: [],
        hotel: null,
        benefit: null,
        currency: null,
        rooms_from: 1,
        rooms_to:1,
        status: true,
        amount: 0,
      }
    }
  },
  computed: {
    ...mapState('fivesClubProfit',['catalogPerformanceConcepts', 'roomsSubTypes']),
    ...mapState("fivesClubCatalogs", ['benefitOptions']),
    ...mapState("start", ["hotels", "currencies"]),
    listyears(){
      return validYears()
    },
    roomsConfig(){
      return this.roomsSubTypes
    }
  },
  methods: {
    ...mapActions('start',['getHotels', 'fetchCurrencies']),
    ...mapActions('fivesClubProfit', ['getInitialContentForBenefitsMonetizationConfig', 'saveBenefitsMonetizationConfig','fetchRoomSubtypes']),
    ...mapMutations('start',['setHotels', 'setCurrencies']),
    ...mapMutations('fivesClubProfit',['setPerformanceConcepts', 'setCatalogPerformanceConcepts']),

    async init() {
      this.isLoadingMonetizingConfig = true

      await this.getInitialContentForBenefitsMonetizationConfig({
        benefits: !this.benefitOptions.length > 0,
        hotels: !this.hotels.length > 0,
        roomsTypes: !this.roomsSubTypes.length > 0,
        currencies: !this.currencies.length > 0
      })

      if (this.$route.params.hotelId || this.$route.params.benefitId) {
        this.newConfig.hotel = this.$route.params.hotelId || null
        this.newConfig.benefit = this.$route.params.benefitId || null
      }

      this.isLoadingMonetizingConfig = false
    },


    async saveConfiguration() {
      const isConfirmed = await this.confirmSaving()
      if (isConfirmed) {
        this.isSavingMonetizingConfig = true

        const payload = {
          ...this.newConfig
        }

        payload.roomsCategory = this.newConfig.roomsCategory.map(roomsCat => roomsCat.id)
        payload.amount = parseFloat(payload.amount).toFixed(2)

        const response = await this.saveBenefitsMonetizationConfig(payload)

        if (response) {
          this.ResetFields()
          this.isSavingMonetizingConfig = false
          this.goToHome({
            reload: true,
            hotelId: this.newConfig.hotel,
            benefitId: this.newConfig.benefit,
          })
        }
        this.isSavingMonetizingConfig = false
      }
    },
    async deleteRoomType(option) {
      option.delete = true
    },
    ResetFields() {
      this.newConfig = {
        roomsCategory: [],
        hotel: null,
        benefit: null,
        currency: null,
        rooms_from: 1,
        rooms_to:1,
        status: false,
        amount: 0,
      }
    },
    async confirmSaving() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Deseas guardar la información?",
        text: "Favor de confirmar",
        showDenyButton: true,
        confirmButtonText: "Sí, guardar",
        denyButtonText: "Cancelar",
        customClass: {
          confirmButton: 'swal-button-yes',
          denyButton: 'swal-button-cancel',
        },
      })
      return isConfirmed
    },
    goToHome(params){
      this.$router.push({ name: 'monetizing-config', params })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>